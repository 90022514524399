body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Work Sans" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 3.5rem;
  color: #212529 !important;
}
hr {
  border-color: rgb(249, 211, 66) !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.home-links {
  color: #212529 !important;
  text-decoration: none;
  background-color: transparent;
  border-bottom: 2px dotted lightgray !important;
}
@media screen and (max-width: 768px) {
  .mobile-page {
    margin-bottom: 1em;
  }
}
/* font families */
h1,
h2,
h3 {
  font-family: courier, sans serif;
}
h4,
h5,
h6,
p,
b,
label {
  font-family: "Work Sans", sans serif;
}

/* base font sizes */
h1 {
  font-size: 8em;
}
@media screen and (max-width: 1024px) {
  h1 {
    font-size: 7em;
  }
}
@media screen and (max-width: 900px) {
  h1 {
    font-size: 4em;
  }
}
@media screen and (max-width: 522px) {
  h1 {
    font-size: 3em;
  }
}
@media screen and (max-width: 400px) {
  h1 {
    font-size: 2em;
  }
}

h2 {
  font-size: 2.5em;
}
@media screen and (max-width: 768px) {
  h2 {
    font-size: 2.25em;
  }
}
@media screen and (max-width: 425px) {
  h2 {
    font-size: 2em;
  }
}
@media screen and (max-width: 375px) {
  h2 {
    font-size: 1.25em;
  }
}
h3 {
  font-size: 2em;
}
@media screen and (max-width: 1024px) {
  h3 {
    font-size: 1.75em;
  }
}
@media screen and (max-width: 768px) {
  h3 {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 375px) {
  h3 {
    font-size: 1.25em;
  }
}
h4 {
  font-size: 1.25em;
}
@media screen and (max-width: 1024px) {
  h4 {
    font-size: 1em;
  }
}
@media screen and (max-width: 768px) {
  h4 {
    font-size: 0.95em;
  }
}
@media screen and (max-width: 375px) {
  h4 {
    font-size: 0.75em;
  }
}
h5 {
  font-size: 1.5em;
}
h6 {
  font-size: 0.95em;
}
p,
label {
  font-size: 1.4em;
}
@media screen and (max-width: 1440px) {
  p,
  label {
    font-size: 1.25em;
  }
}
@media screen and (max-width: 1024px) {
  p,
  label {
    font-size: 1em;
  }
}
@media screen and (max-width: 768px) {
  p,
  label {
    font-size: 0.95em;
  }
}
