.navbar {
  background-color: #222 !important;
  font-family: courier, monospace, sans serif !important;
}

.navbar-nav .nav-link {
  color: #fff !important;
  font-size: 1.2em !important;
}

.nav-link:hover {
  color: #cecece !important;
  border-bottom: 1px solid rgb(249, 211, 66) !important;
}

.navbar-brand {
  font-size: 1.5em !important;
  color: rgb(249, 211, 66) !important;
}

.navbar-brand :hover {
  color: rgb(249, 211, 66) !important;
  border-bottom: 1px solid #fff !important;
}

.nav-item > a,
a.navbar-brand,
a.nav-link {
  border-bottom: none !important;
}
